import axios from "axios";
import {environmentConfig} from "@/config/environmentConfig";

export default class DcbService {
    static async encryptUserMsisdn(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/encrypt-msisdn`, {
                msisdn: msisdn,
            }, {
                headers: {
                    Authorization: 'Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB'
                }
            }
        )
    }
}
